import React, { useEffect } from "react";
import "../stylesheets/main.scss";
import "../stylesheets/fonts.scss";
import "../stylesheets/animations.scss";
import "../stylesheets/variables.scss";
import "../stylesheets/components/header.scss";
import "../stylesheets/components/buttons.scss";
import "../stylesheets/components/forms.scss";
import "../stylesheets/components/list-items.scss";
import "../stylesheets/components/dropdowns.scss";
import "../stylesheets/components/post.scss";
import "../stylesheets/components/modal.scss";
import "../stylesheets/icons.scss";
import "../stylesheets/components/navigator-section.scss";
import "../stylesheets/components/pack-section.scss";
import "../stylesheets/components/breadcrumbs.scss";
import Header from "./header";
import Footer from "./footer";
import SettingsLoader from "./settingsLoader";
import SettingsModal from "./modals/settingsModal";

function Default({ children, footer }) {
  var userAgent = typeof window !== `undefined` ? navigator.userAgent.toLowerCase() : null;

  if (userAgent != null) {
    if (userAgent.indexOf("firefox") > -1 || userAgent.indexOf("MSIE") > -1 || userAgent.indexOf("rv:") > -1) {
      document.body.classList.add("simple-graphics"); // Change styling for unsupported browsers
    }
  }

  useEffect(() => {
    document.body.removeAttribute("event");
  });

  return (
    <>
      <SettingsLoader />
      <Header />
      <main id="main-content" tabIndex="-1">
        {children}
      </main>
      <Footer display={footer} />
      <SettingsModal />
    </>
  );
}

export default Default;
