import React from "react";

export function ThemeIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" stroke="currentColor" fill="currentColor" width="1em" height="1em" viewBox="0 0 4.2333333 4.2333333" version="1.1" className="icon--theme" id="theme-icon">
      <g className="icon--theme__beams">
        <path d="m 2.1166667,0.26399999 v 0.5929822" />
        <path d="M 2.1166667,3.374 V 3.9669822" />
        <path d="M 3.374,2.1166667 H 3.9669822" />
        <path d="m 0.26599999,2.1166667 h 0.5929822" />
        <path d="M 3.4258172,0.80641335 3.0065154,1.2257151" />
        <path d="M 1.2267151,3.0055154 0.80741335,3.4248172" />
        <path d="M 3.0048529,3.0055154 3.4241546,3.4248172" />
        <path d="M 0.80716501,0.80782756 1.2264667,1.2271293" />
      </g>
      <g className="icon--theme__circles">
        <circle cx="2.1166666" cy="2.1166666" r="0.65" />
        <circle style={{ fill: "var(--opposite-background-color)", strokeWidth: "0.4" }} cx="1.8" cy="1.8" r="0.6" className="icon--theme__circles__moon-cut" />
      </g>
    </svg>
  );
}
