import { useEffect } from "react";

export default function SettingsLoader() {
  useEffect(() => {
    const prefersDarkTheme = window.matchMedia("(prefers-color-scheme: dark)").matches;
    const theme = window.localStorage.getItem("theme");
    const layout = window.localStorage.getItem("layout");
    const sidebarAlignment = window.localStorage.getItem("sidebar-alignment");

    if (theme === "light") {
      setLightTheme();
      document.getElementById("settings-modal-light-theme-option").classList.add("radio__option--active");
    } else if (theme === "dark") {
      setDarkTheme();
      document.getElementById("settings-modal-dark-theme-option").classList.add("radio__option--active");
    } else {
      window.localStorage.setItem("theme", "default");
      document.getElementById("settings-modal-default-theme-option").classList.add("radio__option--active");
      if (prefersDarkTheme) {
        setDarkTheme();
      } else {
        setLightTheme();
      }
    }

    if (layout === "sidebar") {
      document.body.setAttribute("layout", "sidebar");
      document.getElementById("settings-modal-sidebar-layout-option").classList.add("radio__option--active");
    } else {
      window.localStorage.setItem("layout", "header"); // For new users
      document.body.setAttribute("layout", "header");
      document.getElementById("settings-modal-header-layout-option").classList.add("radio__option--active");
    }
    if (sidebarAlignment === "right") {
      document.body.setAttribute("sidebar-alignment", "right");
      document.getElementById("settings-modal-right-sidebar-alignment-option").classList.add("radio__option--active");
    } else {
      window.localStorage.setItem("sidebar-alignment", "left"); // For new users
      document.body.setAttribute("sidebar-alignment", "left");
      document.getElementById("settings-modal-left-sidebar-alignment-option").classList.add("radio__option--active");
    }
  });

  function setLightTheme() {
    let themeIcon = document.getElementById("theme-icon");
    document.body.setAttribute("theme", "light");
    themeIcon.setAttribute("status", "dark");
  }

  function setDarkTheme() {
    let themeIcon = document.getElementById("theme-icon");
    document.body.setAttribute("theme", "dark");
    themeIcon.setAttribute("status", "light");
  }

  return null;
}
