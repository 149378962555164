import React from "react";
import { Modal } from "../templates/modal";

export default function SettingsModal() {
  function saveDefaultTheme() {
    const prefersDarkTheme = window.matchMedia("(prefers-color-scheme: dark)").matches;
    window.localStorage.setItem("theme", "default");
    document.getElementById("settings-modal-default-theme-option").classList.add("radio__option--active");
    document.getElementById("settings-modal-dark-theme-option").classList.remove("radio__option--active");
    document.getElementById("settings-modal-light-theme-option").classList.remove("radio__option--active");
    if (prefersDarkTheme) {
      setDarkTheme();
    } else {
      setLightTheme();
    }
  }

  function saveDarkTheme() {
    window.localStorage.setItem("theme", "dark");
    document.getElementById("settings-modal-dark-theme-option").classList.add("radio__option--active");
    document.getElementById("settings-modal-default-theme-option").classList.remove("radio__option--active");
    document.getElementById("settings-modal-light-theme-option").classList.remove("radio__option--active");
    setDarkTheme();
  }
  function saveLightTheme() {
    window.localStorage.setItem("theme", "light");
    document.getElementById("settings-modal-light-theme-option").classList.add("radio__option--active");
    document.getElementById("settings-modal-dark-theme-option").classList.remove("radio__option--active");
    document.getElementById("settings-modal-default-theme-option").classList.remove("radio__option--active");
    setLightTheme();
  }
  function setLightTheme() {
    let themeIcon = document.getElementById("theme-icon");
    document.body.setAttribute("theme", "light");
    themeIcon.setAttribute("status", "dark");
  }
  function setDarkTheme() {
    let themeIcon = document.getElementById("theme-icon");
    document.body.setAttribute("theme", "dark");
    themeIcon.setAttribute("status", "light");
  }

  function saveHeaderLayout() {
    window.localStorage.setItem("layout", "header");
    document.getElementById("settings-modal-header-layout-option").classList.add("radio__option--active");
    document.getElementById("settings-modal-sidebar-layout-option").classList.remove("radio__option--active");
    document.body.setAttribute("layout", "header");
  }
  function saveSidebarLayout() {
    window.localStorage.setItem("layout", "sidebar");
    document.getElementById("settings-modal-sidebar-layout-option").classList.add("radio__option--active");
    document.getElementById("settings-modal-header-layout-option").classList.remove("radio__option--active");
    document.body.setAttribute("layout", "sidebar");
  }

  function saveLeftSidebarAlignment() {
    window.localStorage.setItem("sidebar-alignment", "left");
    document.getElementById("settings-modal-left-sidebar-alignment-option").classList.add("radio__option--active");
    document.getElementById("settings-modal-right-sidebar-alignment-option").classList.remove("radio__option--active");
    document.body.setAttribute("sidebar-alignment", "left");
  }
  function saveRightSidebarAlignment() {
    window.localStorage.setItem("sidebar-alignment", "right");
    document.getElementById("settings-modal-right-sidebar-alignment-option").classList.add("radio__option--active");
    document.getElementById("settings-modal-left-sidebar-alignment-option").classList.remove("radio__option--active");
    document.body.setAttribute("sidebar-alignment", "right");
  }

  return (
    <Modal id="settings" title="Settings">
      <h1>Select Theme</h1>
      <div className="radio">
        <button id="settings-modal-default-theme-option" className="radio__option" onClick={saveDefaultTheme}>
          System
        </button>
        <button id="settings-modal-dark-theme-option" className="radio__option" onClick={saveDarkTheme}>
          Dark
        </button>
        <button id="settings-modal-light-theme-option" className="radio__option" onClick={saveLightTheme}>
          Light
        </button>
      </div>
      <h1>Select Layout</h1>
      <div className="radio">
        <button id="settings-modal-header-layout-option" className="radio__option" onClick={saveHeaderLayout}>
          Header
        </button>
        <button
          id="settings-modal-sidebar-layout-option"
          className="radio__option"
          tooltip="Only available on certain screen sizes"
          onClick={saveSidebarLayout}>
          Sidebar
        </button>
      </div>
      <div className="modal-dialogue--settings__sidebar-aligment">
        <h1>Sidebar Alignment</h1>
        <div className="radio">
          <button id="settings-modal-left-sidebar-alignment-option" className="radio__option" onClick={saveLeftSidebarAlignment}>
            Left
          </button>
          <button id="settings-modal-right-sidebar-alignment-option" className="radio__option" onClick={saveRightSidebarAlignment}>
            Right
          </button>
        </div>
      </div>
    </Modal>
  );
}
