import React from "react";

export function LayoutIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" stroke="currentColor" fill="none" width="1em" height="em" viewBox="0 0 4.2333333 4.2333333" version="1.1" className="icon--layout">
      <rect width="3.5846863" height="3.5846868" x="0.32432353" y="0.3243233" ry="0.1" />
      <path d="M 0.36666665,1.5 H 3.8666667" />
      <path className="icon--layout__sidebar" d="m 1.5458355,1.5103411 v 2.357" />
    </svg>
  );
}
