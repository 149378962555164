import React from "react";

export default function LogoIcon({ animated }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 5.8208329 5.8208336"
      version="1.1"
      className={"icon--logo" + (animated ? " icon--logo--animated" : "")}>
      <circle className="icon--logo__main-circle" cx="2.9104166" cy="2.9104166" r="2.0486834" />
      <path
        className="icon--logo__c"
        d="M 4.0154544,4.6338498 A 2.0509343,2.0509343 0 0 1 1.6331308,4.5209968 2.0509343,2.0509343 0 0 1 0.96838054,2.2305146 2.0509343,2.0509343 0 0 1 2.9201421,0.85981103"
      />
      <g className="icon--logo__head">
        <g className="icon--logo__skull">
          <g className="icon--logo__face" transform="rotate(45,0.20168551,5.755614)">
            <rect className="icon--logo__eye" width="0.59999907" height="0.79999906" x="1.0880005" y="1.1560005" ry="0.055345833" />
            <rect className="icon--logo__eye" width="0.59999907" height="0.79999906" x="-3.1453328" y="1.1560005" ry="0.055345833" transform="scale(-1,1)" />
            <path className="icon--logo__wattle" d="m 2.122898,2.2479873 c 0,0.4998775 -0.093544,0.7161987 -0.213398,0.8155897" />
            <path className="icon--logo__beak" d="M 1.0420623,2.1166667 H 3.1912711" />
          </g>
        </g>
      </g>
    </svg>
  );
}
