import React from "react";
import { Link } from "gatsby";
import SettingsIcon from "./icons/settingsIcon";
import { ModalOpenButton } from "./templates/modal";

function Footer({ display }) {
  return (
    <div className="footer" style={{ display }}>
      <Link className="footer__link" title="Submit Feedback" to="/feedback">
        Feedback
      </Link>
      |
      <Link className="footer__link" title="Report a bug" to="/bugs">
        Bug Report
      </Link>
      |
      <Link className="footer__link" title="View Privacy Policy" to="/privacy">
        Privacy Policy
      </Link>
      <ModalOpenButton className="button--settings" id="settings">
        <SettingsIcon />
      </ModalOpenButton>
    </div>
  );
}

Footer.defaultProps = {
  display: "block",
};

export default Footer;
