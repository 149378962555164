import React from "react";

export default function HomeIcon({ className }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" stroke="currentColor" width="1em" height="1em" viewBox="0 0 4.2333333 4.2333333" version="1.1" className={`icon--home ${className}`}>
      <path className="icon--home__wall" d="M 1.0166667,3.545 1.0169596,2.5 2.1484735,1.5 3.2166667,2.5 v 1.295 h -2.2" />
      <path className="icon--home__roof" d="M 0.60960367,1.8836303 2.1495,0.65" />
      <path className="icon--home__roof" d="M 3.6893963,1.8836303 2.1495,0.65" />
    </svg>
  );
}
