import React from "react";
import { Link } from "gatsby";
import { FaBug, FaCommentAlt, FaBars, FaBookOpen, FaToolbox } from "react-icons/fa";
import { FiArrowRight } from "react-icons/fi";
import { ThemeIcon } from "./icons/themeIcon";
import { LayoutIcon } from "./icons/layoutIcon";
import HomeIcon from "./icons/homeIcon";

const Header = () => {
  function toggleLayout() {
    const layout = window.localStorage.getItem("layout");
    if (layout === "header") {
      window.localStorage.setItem("layout", "sidebar");
      document.body.setAttribute("layout", "sidebar");
      document.getElementById("settings-modal-sidebar-layout-option").classList.add("radio__option--active");
      document.getElementById("settings-modal-header-layout-option").classList.remove("radio__option--active");
    } else {
      window.localStorage.setItem("layout", "header");
      document.body.setAttribute("layout", "header");
      document.getElementById("settings-modal-header-layout-option").classList.add("radio__option--active");
      document.getElementById("settings-modal-sidebar-layout-option").classList.remove("radio__option--active");
    }
  }

  function toggleSidebarAlignment() {
    const alignment = window.localStorage.getItem("sidebar-alignment");
    if (alignment === "left") {
      window.localStorage.setItem("sidebar-alignment", "right");
      document.body.setAttribute("sidebar-alignment", "right");
      document.getElementById("settings-modal-right-sidebar-alignment-option").classList.add("radio__option--active");
      document.getElementById("settings-modal-left-sidebar-alignment-option").classList.remove("radio__option--active");
    } else {
      window.localStorage.setItem("sidebar-alignment", "left");
      document.body.setAttribute("sidebar-alignment", "left");
      document.getElementById("settings-modal-left-sidebar-alignment-option").classList.add("radio__option--active");
      document.getElementById("settings-modal-right-sidebar-alignment-option").classList.remove("radio__option--active");
    }
  }

  function toggleTheme() {
    const theme = document.body.getAttribute("theme");
    let themeIcon = document.getElementById("theme-icon");
    if (theme === "dark") {
      window.localStorage.setItem("theme", "light");
      document.body.setAttribute("theme", "light");
      themeIcon.setAttribute("status", "darkening");
      document.getElementById("settings-modal-light-theme-option").classList.add("radio__option--active");
      document.getElementById("settings-modal-dark-theme-option").classList.remove("radio__option--active");
      document.getElementById("settings-modal-default-theme-option").classList.remove("radio__option--active");
    } else {
      window.localStorage.setItem("theme", "dark");
      document.body.setAttribute("theme", "dark");
      themeIcon.setAttribute("status", "lightening");
      document.getElementById("settings-modal-dark-theme-option").classList.add("radio__option--active");
      document.getElementById("settings-modal-default-theme-option").classList.remove("radio__option--active");
      document.getElementById("settings-modal-light-theme-option").classList.remove("radio__option--active");
    }
  }

  return (
    <nav className="header">
      <label for="check" control="header-links-mobile-toggle" class="checkbtn">
        <FaBars />
      </label>
      <input type="checkbox" id="check" />
      <h1 className="header__title">
        <Link to="/">QuazChick</Link>
      </h1>
      <a href="#main-content" className="button--skip-navigation">
        Skip Navigation
      </a>
      <ul className="header__nav">
        <li>
          <Link activeClassName="header__link--active--home header__link--active" to="/">
            <HomeIcon className="sidebar-icon" />
            <p>Home</p>
          </Link>
        </li>
        <li>
          <Link activeClassName="header__link--active--pack header__link--active" partiallyActive={true} to="/packs">
            <FaToolbox className="sidebar-icon" />
            <p>Packs</p>
          </Link>
        </li>
        <li>
          <Link activeClassName="header__link--active--tutorial header__link--active" to="/tutorials">
            <FaBookOpen className="sidebar-icon" />
            <p>Tutorials</p>
          </Link>
        </li>
        <li>
          <Link activeClassName="header__link--active--feedback header__link--active" to="/feedback">
            <FaCommentAlt className="sidebar-icon" />
            <p>Submit Feedback</p>
          </Link>
        </li>
        <li>
          <Link activeClassName="header__link--active--bugs header__link--active" to="/bugs">
            <FaBug className="sidebar-icon" />
            <p>Report a Bug</p>
          </Link>
        </li>
        <li className="separator"></li>
        <li className="theme-toggle-wrapper">
          <button onClick={toggleTheme} className="theme-toggle" id="theme-toggle" aria-label="Toggle Theme">
            <ThemeIcon />
          </button>
        </li>
        <li className="sidebar-alignment-toggle-wrapper">
          <button onClick={toggleSidebarAlignment} className="layout-toggle layout-toggle--sidebar-alignment" aria-label="Toggle Layout">
            <FiArrowRight />
          </button>
        </li>
        <li>
          <button onClick={toggleLayout} className="layout-toggle" aria-label="Toggle Sidebar Alignment">
            <LayoutIcon />
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Header;
