import React from "react";

export default function SettingsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      stroke="currentColor"
      width="1em"
      height="1em"
      viewBox="0 0 4.2333333 4.2333333"
      version="1.1"
      className={`icon--settings`}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M 1.8117757,0.21704261 C 1.7433652,0.21715094 1.7011081,0.56716563 1.6080003,0.70146614 L 1.298112,0.85834712 0.8443929,0.68316401 0.42219646,1.2128475 0.68884685,1.6546809 c -0.0323142,0.1050692 -0.0453253,0.2245517 -0.089625,0.3206716 L 0.1870687,2.2200211 0.33434654,2.8773453 0.80873617,2.9290216 1.0635009,3.2318457 1.0118245,3.6938331 1.6148885,4.016294 1.9513021,3.6576595 c 0.1065255,0.00548 0.2221414,-0.010981 0.3229682,0.00826 L 2.624646,4.016294 3.2576823,3.6974502 3.206006,3.2318457 3.424597,2.9491755 3.8907185,2.8969824 4.0462647,2.244309 3.6478392,1.9972957 C 3.6248324,1.8826757 3.5785509,1.7649561 3.5698228,1.6522378 L 3.8111369,1.2205989 3.3930746,0.68316401 2.9352213,0.84646156 C 2.8272546,0.7889656 2.7016194,0.74737643 2.6047353,0.67990302 L 2.4411946,0.21704261 Z M 2.1166666,1.3167171 C 2.5744068,1.3028036 2.9730631,1.7444766 2.9124833,2.1984565 2.8793212,2.6546082 2.4006569,3.0058505 1.9554529,2.900359 1.5054186,2.8213754 1.2039754,2.3111131 1.3526907,1.8787937 1.4505295,1.5507359 1.7739318,1.3117275 2.1166666,1.3167171 Z"
      />
    </svg>
  );
}
