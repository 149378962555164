import React, { useEffect } from "react";
import PropTypes from "prop-types";

// Modal
function Modal({ title, children, id, status, closable }) {
  function closeModal() {
    document.getElementById(`${id}-modal`).setAttribute("status", "closing");

    setTimeout(function () {
      document.getElementById(`${id}-modal`).setAttribute("status", "closed");
    }, 120);
  }

  function handleKeyDown(e) {
    if (e.key === "Escape" && document.getElementById(`${id}-modal`).getAttribute("status") === "open") closeModal();
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  });

  const closeVisible = closable === "true" ? true : false;

  return (
    <>
      <modal status={status} id={`${id}-modal`}>
        <div className="modal-dialogue">
          <h1 className="modal-dialogue__title" id={`${id}-modal-title`}>
            {title}
          </h1>
          <button
            style={{ display: closeVisible ? "flex" : "none" }}
            id={`${id}-modal-close`}
            onClick={closeModal}
            className="modal-dialogue__close"
            aria-label="Close Modal">
            &times;
          </button>
          <div className="modal-dialogue__content">{children}</div>
        </div>
      </modal>
    </>
  );
}

Modal.defaultProps = {
  title: "Modal",
  id: "default",
  status: "closed",
  closable: "true",
};
Modal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
};

// Button
function ModalOpenButton({ className, children, id, style }) {
  function openModal() {
    document.getElementById(`${id}-modal`).setAttribute("status", "open");
    document.getElementById(`${id}-modal-close`).focus();
  }

  return (
    <button style={style} className={className} onClick={openModal}>
      {children}
    </button>
  );
}

ModalOpenButton.defaultProps = {
  children: "Open Modal",
  id: "default",
};
ModalOpenButton.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.string,
};

// Export
export { Modal, ModalOpenButton };
